<template>
    <div class="login_content">

        <div class="login_panel"  v-if="!isMaintaining">
            <div class="ipt iptmt">
                <input type="text" v-model="user.username" placeholder="아이디" @keyup.enter="doLogin">
            </div>
            <div class="ipt" style="margin-top: 10px">
                <input type="password" v-model="user.passwd" placeholder="비밀번호" @keyup.enter="doLogin">
            </div>
            <div class="btns">
                <router-link tag="button" to="" @click.native="doLogin">로그인</router-link>
                <router-link tag="button" to="/register">회원가입</router-link>
            </div>
            <div class="tel">
                <a href="https://t.me/kisabet" target="_blank">
                    <img alt="" src="../../assets/images/banner/gisa/tel.png" style="width: 100%">
                </a>
            </div>
        </div>

        <div class="login_panel"  v-if="isMaintaining" v-html="content" style="padding-top: 150px">

        </div>

    </div>



</template>

<script>
    import {getSiteStatus, getUserInfo, login} from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_USER_INFO} from "../../store/mutation-types";


    export default {
        name: "login",
        components: {},
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                },
                isShowMessage: false,
                content: '',
                isMaintaining :false
            }
        },
        methods: {
            doLogin() {

                this.$store.commit(RECEIVE_SHOW_LOADING);
                login(this.user).then(res => {
                    if (res.data.success) {
                        getUserInfo().then(res => {
                            //window.sessionStorage.setItem("isLogin", 'true');
                            this.$store.state.isLogin = true;
                            this.$store.state.userInfo = res.data.data
                            //this.$store.commit(RECEIVE_USER_INFO, {userInfo});
                            this.$store.commit(RECEIVE_HIDE_LOADING);
                            this.$router.push('/main').catch(error => {
                                console.info(error.message)
                            });
                            this.$store.commit(RECEIVE_HIDE_LOADING);
                        }, err => {
                            console.log(err)
                            this.$store.commit(RECEIVE_HIDE_LOADING);
                        })
                    } else {
                        this.$store.commit(RECEIVE_HIDE_LOADING);
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            donotlogin() {

            },

        },
        created() {
            getSiteStatus().then(res => {
                if(!res.data.success){
                    this.isMaintaining = true
                } else {
                    this.isMaintaining = false
                }
                this.content = res.data.msg
            })
        },
        computed: {
            allowUsername() {
                return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
            },
            allowPasswd() {
                return /^.{3,30}$/.test(this.passwd)
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    /*@import url("../../assets/css/lore.css");*/
    .login_content{
        width: 100%;
        height: 100vh;
        position: fixed;
        background: url("../../assets/images/login/red/login_bg.gif") center no-repeat;
        background-size: cover;
    }
    .login_panel{
        width: 500px;
        height: 480px;
        position: absolute;
        left: 50%;
        top: 50%;
        background: url("../../assets/images/login/red/login_panel_bg.png") center no-repeat;
        background-size: 100%;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
        transform: translateX(-50%) translateY(-50%) scale(1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .ipt{
        width: 391px;
        height: 57px;
        background: url("../../assets/images/login/red/txtbg.jpg") center no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ipt input{
        width: 300px;
        height: 50px;
        text-align: center;
        border: 0 transparent;
    }
    .iptmt{
        margin-top: 130px
    }
    .btns{
        width: 391px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    .btns button{
        width: 49.5%;
        height: 40px;
        background: linear-gradient(180deg, #353535, #070707) !important;
        font-size: 15px;
        font-weight:bold;
        border-radius: 3px;
    }
    .btns button:hover{
        color: #ff4d4d;
    }
    .tel{
        width: 375px;
        margin-top: 20px;
        height: 100px;
    }

    @media screen and (max-width: 1024px) {
        .login_content{
            background: url("../../assets/images/login/red/login_bg_mobile.gif") center no-repeat!important;
        }
        .login_panel{
            width: 370px!important;
            background: url("../../assets/images/login/red/login_panel_bg_mobile.png") center no-repeat!important;
        }
        .ipt {
            width: 320px!important;
            height: 40px;
            background: url("../../assets/images/login/red/txtbg_mobile.png") center no-repeat;
        }
        .ipt input{
            width: 250px!important;
            height: 32px!important;
        }
        .btns{
            width: 320px;
        }
        .tel{
            width: 320px;
        }
        .iptmt{
            margin-top: 145px
        }
    }
</style>